import { useNavigate } from 'react-router-dom';

// Icons for help
import { ReactComponent as HelpIcon } from '@/shared/assets/help.svg';
import { ReactComponent as InvoiceIcon } from '@/shared/assets/invoice.svg';
import { ReactComponent as LegalIcon } from '@/shared/assets/legal.svg';
import { ReactComponent as MoneyClipIcon } from '@/shared/assets/money-clip.svg';
import { ReactComponent as MoneyInsertIcon } from '@/shared/assets/money-insert.svg';
import { ReactComponent as RightArrow } from '@/shared/assets/right-arrow.svg';
import { ReactComponent as RoutingIcon } from '@/shared/assets/routing.svg';
import { ReactComponent as UserIcon } from '@/shared/assets/user.svg';
import DividedCard from '@/shared/components/DividedCard';
import { useSwipeable } from 'react-swipeable';

import {
  useCustomerFromProfile,
  useWorkerProfile,
  useWorkerProfileIsSampleAccount,
} from '@/services/useWorkerProfile';
import { toast } from '@/shared/components/Toaster/Toaster';
import useZendeskChat from '@/shared/hooks/useZendeskChat';
import { Customer, PayoutMethod } from '@/shared/types';
import {
  ActionContainer,
  LinkContainer,
  SettingsViewContainer,
  SubTitle,
  TextContainer,
  Title,
} from './SettingsPage.styled';

export default function SettingsPage() {
  const { data: customer } = useCustomerFromProfile();
  const { data: workerProfile } = useWorkerProfile();
  const { data: isSampleAccount } = useWorkerProfileIsSampleAccount();
  const hideTaxes = (customer as Customer).featureFlags?.taxesDisabled?.enabled;
  const navigate = useNavigate();

  // Do not show banking related options to a bankless earner
  const hideBankingOnlyNavItems =
    !workerProfile?.worker?.bankingUserId &&
    workerProfile?.profile?.payoutMethod?.selected === PayoutMethod.ACHDirectDeposit;

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      navigate('/dashboard/withdraw');
    },
  });

  const [_, setIsZendeskChatOpen] = useZendeskChat();

  const hasMultiplePayoutMethodsEnabled =
    workerProfile?.profile?.additionalPayoutMethods?.achDirectDeposit?.enabled ||
    workerProfile?.profile?.additionalPayoutMethods?.pushToCardDirectDeposit?.enabled ||
    customer?.payoutMethods?.achDirectDeposit?.enabled ||
    customer?.payoutMethods?.pushToCardDirectDeposit?.enabled;

  const toastIfSampleAccount = (actionFn: () => void) =>
    isSampleAccount
      ? () =>
          toast({
            message: 'Start receiving payouts to unlock this functionality!',
            type: 'info',
            duration: 5000,
          })
      : actionFn;

  return (
    <SettingsViewContainer {...swipeHandlers}>
      <DividedCard
        elements={[
          <LinkContainer
            key="personal-info"
            onClick={() => {
              navigate('/user-profile');
            }}
          >
            <UserIcon />
            <TextContainer>
              <Title>Personal Information</Title>
              <SubTitle>Edit your profile</SubTitle>
            </TextContainer>
            <ActionContainer>
              <RightArrow />
            </ActionContainer>
          </LinkContainer>,
          ...(hideBankingOnlyNavItems
            ? []
            : [
                <LinkContainer
                  key="bank-account-information"
                  onClick={toastIfSampleAccount(() => {
                    navigate('/bank-account-information');
                  })}
                >
                  <RoutingIcon />
                  <TextContainer>
                    <Title>Bank Account</Title>
                  </TextContainer>
                  <ActionContainer>
                    <RightArrow />
                  </ActionContainer>
                </LinkContainer>,
                <LinkContainer
                  key="statements"
                  onClick={toastIfSampleAccount(() => {
                    navigate('/statements');
                  })}
                >
                  <MoneyClipIcon />
                  <TextContainer>
                    <Title>Statements</Title>
                  </TextContainer>
                  <ActionContainer>
                    <RightArrow />
                  </ActionContainer>
                </LinkContainer>,
              ]),
          ...(hasMultiplePayoutMethodsEnabled
            ? [
                <LinkContainer
                  key="manage payout methods"
                  onClick={() => {
                    navigate('/manage-payout-methods');
                  }}
                >
                  <MoneyInsertIcon />
                  <TextContainer>
                    <Title>Manage Payout Methods</Title>
                  </TextContainer>
                  <ActionContainer>
                    <RightArrow />
                  </ActionContainer>
                </LinkContainer>,
              ]
            : []),
          <LinkContainer
            key="pay-stubs"
            onClick={toastIfSampleAccount(() => {
              navigate('/pay-stubs');
            })}
          >
            <MoneyClipIcon />
            <TextContainer>
              <Title>Pay Stubs</Title>
            </TextContainer>
            <ActionContainer>
              <RightArrow />
            </ActionContainer>
          </LinkContainer>,
          ...(hideTaxes
            ? []
            : [
                <LinkContainer
                  key="taxes"
                  onClick={toastIfSampleAccount(() => {
                    navigate('/taxes');
                  })}
                >
                  <InvoiceIcon />
                  <TextContainer>
                    <Title>Taxes</Title>
                  </TextContainer>
                  <ActionContainer>
                    <RightArrow />
                  </ActionContainer>
                </LinkContainer>,
              ]),
        ]}
      />
      <DividedCard
        elements={[
          <LinkContainer
            key="support"
            onClick={() => {
              navigate('/support');
            }}
          >
            <HelpIcon />
            <TextContainer>
              <Title>Help center</Title>
              <SubTitle>Browse FAQ or contact Checkr Support</SubTitle>
            </TextContainer>
            <ActionContainer>
              <RightArrow />
            </ActionContainer>
          </LinkContainer>,
          <LinkContainer
            key="chat-support"
            onClick={() => {
              setIsZendeskChatOpen(true);
            }}
          >
            <HelpIcon />
            <TextContainer>
              <Title>Chat with a Support Agent</Title>
              <SubTitle>
                Live chat is available Monday through Friday, from 9AM to 6PM Eastern Time.
              </SubTitle>
            </TextContainer>
            <ActionContainer>
              <RightArrow />
            </ActionContainer>
          </LinkContainer>,

          <LinkContainer
            key="legal"
            onClick={() => {
              navigate('/legal');
            }}
          >
            <LegalIcon />
            <TextContainer>
              <Title>Legal</Title>
            </TextContainer>
            <ActionContainer>
              <RightArrow />
            </ActionContainer>
          </LinkContainer>,
        ]}
      />
    </SettingsViewContainer>
  );
}
