/**
 * Onboarding-related mutations and endpoints
 */
import { ConsentDocumentIds, DateString } from '@/shared/types';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import serverApi from './serverApi';

import { trackAmplitudeEvent } from '@/shared/analytics';

// POST /profile/complete_onboarding - Mark the onboarding tutorial as complete
async function completeOnboardingTutorial() {
  const response = await serverApi.post('/profile/complete_onboarding');
  return response.data;
}

export const useCompleteOnboardingTutorial = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => completeOnboardingTutorial(),
    onSuccess: () => {
      // On success, clear the worker Profile so we refetch it!
      return queryClient.invalidateQueries({ queryKey: ['workerProfile'] });
    },
  });
};

// POST /notify/sms
async function sendExternalSMSLink({ smsBody }: { smsBody: string }) {
  const response = await serverApi.post('/notify/sms', {
    body: smsBody,
  });
  return response.data;
}

export const useSendExternalSMSLink = (
  options?: Partial<UseMutationOptions<unknown, unknown, unknown>>
) => {
  return useMutation({
    mutationFn: ({ smsBody }: { smsBody: string }) => sendExternalSMSLink({ smsBody }),
    ...options,
  });
};

type BusinessDetails = {
  ein: string;
  formationDate: DateString;
  businessName: string;
  entityType: string;
};

async function postBusinessConfirm(businessDetails: BusinessDetails) {
  const response = await serverApi.post('/profile/business_confirm', businessDetails);
  return response.data;
}

export const useZDBusinessConfirm = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (businessDetails: BusinessDetails) => postBusinessConfirm(businessDetails),
    onSuccess: () => {
      // On success, clear the worker Profile so we refetch it!
      return queryClient.invalidateQueries({ queryKey: ['workerProfile'] });
    },
    // Catch 4XX errors to handle in-component. Unexpected 5XX Errors are rendered on error page
    useErrorBoundary: (err: AxiosError) => {
      const statusCode = err?.response?.status || 500;
      return statusCode < 400 || statusCode >= 500;
    },
  });
};

interface WorkerProfilePIIProps {
  ssn?: string;
  dob?: string;
  firstName?: string;
  lastName?: string;
  address?: {
    street?: string;
    street2?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
  email?: string;
  workerType: 'individual' | 'business';
}
async function postConfirmWorkerProfile(workerPii: WorkerProfilePIIProps) {
  const response = await serverApi.post('/profile/confirm_pii', workerPii);
  return response.data;
}

export const useConfirmWorkerProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (workerPii: WorkerProfilePIIProps) => postConfirmWorkerProfile(workerPii),
    onSuccess: () => {
      // On success, clear the worker Profile so we refetch it!
      return queryClient.invalidateQueries({ queryKey: ['workerProfile'] });
    },
    // Catch 4XX errors to handle in-component. Unexpected 5XX Errors are rendered on error page
    useErrorBoundary: (err: AxiosError) => {
      const statusCode = err?.response?.status || 500;
      return statusCode < 400 || statusCode >= 500;
    },
  });
};

// NOTE(Carter): This is very cool - we can group multiple server calls into a single
// mutation!
async function postConsentAndActivate({ consents }: { consents: Array<boolean> }) {
  const consentDate = new Date();
  await serverApi.post('/profile/consents', [
    {
      documentId: ConsentDocumentIds.PowerOfAttorney,
      documentVersion: 'v1.0',
      accepted: consents[0],
      signerTimestamp: consentDate,
    },
    {
      documentId: ConsentDocumentIds.SolePropAttestation,
      documentVersion: 'v1.0',
      accepted: consents[1],
      signerTimestamp: consentDate,
    },
    {
      documentId: ConsentDocumentIds.TermsOfService,
      documentVersion: 'v1.0',
      accepted: consents[2],
      signerTimestamp: consentDate,
    },
    {
      documentId: ConsentDocumentIds.SponsorBankDisclosure,
      documentVersion: 'v1.0',
      accepted: consents[3],
      signerTimestamp: consentDate,
    },
    {
      documentId: ConsentDocumentIds.AstraDisclosure,
      documentVersion: 'v1.0',
      accepted: consents[4],
      signerTimestamp: consentDate,
    },
  ]);

  trackAmplitudeEvent('Legal agreement confirmed');
  // After this completes, activate the worker.
  const activationResponse = await serverApi.post('/profile/activate');
  return activationResponse.data;
}

export const useConsentAndActivate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (consents: Array<boolean>) => postConsentAndActivate({ consents }),
    onSuccess: () => {
      // On success, clear the worker Profile so we refetch it!
      window.parent.postMessage('checkr-pay.activation.successful', '*');
      return queryClient.invalidateQueries({ queryKey: ['workerProfile'] });
    },
    onError: () => {
      window.parent.postMessage('checkr-pay.activation.failed', '*');
      return queryClient.invalidateQueries({ queryKey: ['workerProfile'] });
    },
    // Catch 4XX errors to handle in-component. 400 errors are handled in-page, taking us to
    // the activation status page (with status 'failed')
    useErrorBoundary: (err: AxiosError) => err?.response?.status !== 400,
  });
};
