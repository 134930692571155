import { LoadingScreen, TabWrapper } from '@/app/wrappers/Containers';
import { useBankingAccount } from '@/services/useBankingAccount';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import PageNavBar from '@/shared/components/PageNavBar';
import { NormalText, TitleText } from '@/shared/components/Text.styled';
import { formatCentsAsCurrency, SUPPORT_HOME_PAGE } from '@/shared/helpers';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { getWorkerACHLimits } from '@/shared/withdrawals';
import { Button, Icon, Spinner } from '@checkrx/pay-component-library';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  padding: 0 24px;
`;

const Title = styled(TitleText)`
  text-align: center;
  color: ${colors.accentRed};
  font-size: ${fontSizes.big};
  margin-top: 12px;
`;

const Card = styled.div`
  background: ${colors.backgroundGrey};
  border: 1px solid ${colors.primaryGreyLight};
  border-radius: 8px;
  padding: 12px;
  width: 100%;
`;

const CardTitle = styled.h3`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  margin-bottom: 4px;
  color: ${(p) => p.theme.colors.text.primary};
`;

const CardDescription = styled.p`
  margin-bottom: 8px;
  font-size: ${fontSizes.smallMedium};
`;

const BalanceText = styled.span`
  display: block;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  color: ${colors.accentTeal};
  margin: 4px 0;
`;

const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DocumentButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  background: ${(p) => p.theme.colors.backgrounds.app};
  border: 1px solid ${colors.primaryBlue};
  border-radius: 8px;
  cursor: pointer;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.smallMedium};
  color: ${(p) => p.theme.colors.text.primary};

  &:hover {
    background: ${colors.backgroundGrey};
  }
`;

const HelpText = styled.span`
  text-align: center;
  color: ${(p) => p.theme.colors.text.secondary};
  font-size: ${fontSizes.small};
`;

const HelpLink = styled.span`
  color: ${colors.accentTeal};
  text-decoration: underline;
  cursor: pointer;
`;

const documentLinks = [
  { title: 'Account Statements', path: '/statements' },
  { title: 'Pay Stubs', path: '/pay-stubs' },
  { title: 'Tax Documents', path: '/taxes' },
] as const;

export default function SunsetPage() {
  const navigate = useNavigate();
  const sendToSupportPage = useExternalLinkPoster({
    link: SUPPORT_HOME_PAGE,
    prependText: 'Checkr Pay Support',
  });
  const { data: workerProfile } = useWorkerProfile();
  const sunsetSettings = workerProfile?.customer?.sunsetSettings;
  const { data: bankingData, isLoading: isBankingLoading } = useBankingAccount();

  if (isBankingLoading) {
    return (
      <LoadingScreen>
        <Spinner />
        <NormalText>Loading your account information...</NormalText>
      </LoadingScreen>
    );
  }

  const { accountAvailableBalance } = getWorkerACHLimits(bankingData);
  const currentBalanceFormatted = formatCentsAsCurrency(accountAvailableBalance || 0);

  return (
    <TabWrapper>
      <PageNavBar navigateBackTo="/dashboard" />
      <PageContainer>
        <Title>Important Notice: Checkr Pay Service Discontinuation</Title>

        <NormalText>
          {sunsetSettings?.walletMessage ||
            'We want to inform you that Checkr Pay will be discontinued. ' +
              'Please take the following important actions:'}
        </NormalText>

        <Card>
          <CardTitle>Withdraw Your Funds</CardTitle>
          <CardDescription>
            Please withdraw all remaining funds from your Checkr Pay account as soon as possible.
          </CardDescription>
          <BalanceText>Current Balance: {currentBalanceFormatted}</BalanceText>
          <Button
            width="100%"
            text="Withdraw funds"
            colorVariant="light"
            sizeVariant="small"
            onClick={() => navigate('/withdraw')}
          />
        </Card>

        <Card>
          <CardTitle>Update Recurring Payments</CardTitle>
          <CardDescription>
            Update any services where you use your Checkr Pay card for recurring billing to prevent
            service interruptions.
          </CardDescription>
        </Card>

        <Card>
          <CardTitle>Download Important Documents</CardTitle>
          <CardDescription>
            Make sure to download all your statements, tax documents, and other important records
            before they become unavailable.
          </CardDescription>
          <DocumentList>
            {documentLinks.map((doc) => (
              <DocumentButton key={doc.path} onClick={() => navigate(doc.path)}>
                {doc.title}
                <Icon name="chevron-right" />
              </DocumentButton>
            ))}
          </DocumentList>
        </Card>

        <HelpText>
          Need help? <HelpLink onClick={sendToSupportPage}>Visit our help center</HelpLink> for more
          information about the service discontinuation.
        </HelpText>
      </PageContainer>
    </TabWrapper>
  );
}
