import './resets.css';

import { ErrorBoundary } from 'react-error-boundary';
import {
  createMemoryRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';

// Import Application-level Logic & Logic Wrappers
import { AuthProvider } from '@/services/useAuth';
import { LoadingGuard } from './wrappers/LoadingGuard';
import { TempAuthTokenGuard } from './wrappers/TempAuthTokenGuard';
import { WorkerVerificationGuard } from './wrappers/WorkerVerificationGuard';

import { LoadingScope } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';
import BaseErrorFallback from './wrappers/BaseErrorFallback';
import { AppContainer, LoadingScreen } from './wrappers/Containers';
import RoutingError from './wrappers/RoutingErrorFallback';

// Logistic Pages
import ErrorPage from '@/pages/errors/ErrorPage';
import HomePageRedirector from '@/pages/home/HomePageRedirector';

// Onboarding Pages
import ActivationStatusPage from '@/pages/onboarding/activation-status/ActivationStatusPage';
import ConfirmPIIPage from '@/pages/onboarding/confirm-pii/ConfirmPIIPage';
import ConsentsPage from '@/pages/onboarding/consents/ConsentsPage';
import OnboardingLayout from '@/pages/onboarding/OnboardingLayout';
import OnboardingRedirector from '@/pages/onboarding/OnboardingRedirector';
import SplashScreen from '@/pages/onboarding/splash/SplashScreen';
import TutorialPage from '@/pages/onboarding/tutorial/TutorialPage';

// Verification Pages
import NewContactPage from '@/pages/verification/NewContactPage';
import ResetRequestPage from '@/pages/verification/ResetRequestPage';
import ResetRequestSuccessPage from '@/pages/verification/ResetRequestSuccessPage';
import VerificationLayout from '@/pages/verification/VerificationLayout';
import VerificationPage from '@/pages/verification/VerificationPage';
import VerifyNewContactPage from '@/pages/verification/VerifyNewContactPage';
import VerifySecondaryContactPage from '@/pages/verification/VerifySecondContactPage';

// Dashboard Pages
import CardPage from '@/pages/dashboard/card/CardPage';
import DashboardLayout from '@/pages/dashboard/DashboardLayout';
import HomePage from '@/pages/dashboard/home/HomePage';
import SettingsPage from '@/pages/dashboard/settings/SettingsPage';
import WithdrawPage from '@/pages/dashboard/withdraw/WithdrawPage';
import TransactionDetailPage from '@/pages/transactions/TransactionDetailPage';
import TransactionHistoryPage from '@/pages/transactions/TransactionHistoryPage';
import TransactionsLayout from '@/pages/transactions/TransactionsLayout';
import WithdrawalHistoryPage from '@/pages/transactions/WithdrawalHistoryPage';
import { WorkerIsActiveGuard } from './wrappers/WorkerIsActiveGuard';

// Withdrawal Flow Page
import WithdrawalFlowLayout from '@/pages/withdrawal-flow/WithdrawalFlowLayout';
import RequestWithdrawalPage from '@/pages/withdrawal-flow/WithdrawalRequestPage';
import WithdrawalResultPage from '@/pages/withdrawal-flow/WithdrawalResultsPage';
import ReviewWithdrawalPage from '@/pages/withdrawal-flow/WithdrawalReviewPage';

// External Bank Linking Pages
import LinkBankLayout from '@/pages/link-bank/LinkBankLayout';
import LinkBankManuallyPage from '@/pages/link-bank/LinkBankManuallyPage';
import LinkBankResultView from '@/pages/link-bank/LinkBankResultsPage';
import WithdrawalLinkWithPlaidPage from '@/pages/link-bank/WithdrawalLinkWithPlaidPage';

// Manage Payout Method Pages
// eslint-disable-next-line max-len
import LinkDirectDepositBankManuallyPage from '@/pages/manage-payout-method/LinkDirectDepositBankManuallyPage';
import ManagePayoutMethodPage from '@/pages/manage-payout-method/ManagePayoutMethodPage';

// Statements Pages
import StatementsLayout from '@/pages/statements/StatementsLayout';
import StatementsPage from '@/pages/statements/StatementsPage';

// Pay Stubs Pages
import PayStubsLayout from '@/pages/pay-stubs/PayStubsLayout';

// Support and one-off pages
import { DashboardHistoryProvider } from '@/pages/dashboard/useDashboardHistory';
import LegalPage from '@/pages/legal/LegalPage';
import ManagePinPage from '@/pages/support/ManagePin/ManagePinPage';
import PersonalInfoPage from '@/pages/support/PersonalInfoPage';
import SupportPage from '@/pages/support/SupportPage';
import TaxesPage from '@/pages/taxes/TaxesPage';
import SigninPage from '@/pages/verification/SigninPage';
import { ThemePreferenceProvider } from '@/services/useCustomerTheme';
import { WithdrawalAmountProvider } from '@/services/useWithdrawalRequestAmount';
// import { useLocalStorage } from '@/services/helpers';
import DevLoginPage from '@/pages/dev-tools/DevLoginPage';
import LinkDirectDepositLayout from '@/pages/manage-payout-method/LinkDirectDepositLayout';
import LinkDirectDepositResultPage from '@/pages/manage-payout-method/LinkDirectDepositResultPage';
import SelectPayoutMethodPage from '@/pages/manage-payout-method/SelectPayoutMethodPage';
import { BusinessConfirm } from '@/pages/onboarding/business/BusinessConfirm';
import SunsetPage from '@/pages/sunset/SunsetPage';
import BankingInformationPage from '@/pages/support/BankInformation/BankingInformationPage';
import { Analytics } from '@/shared/analytics';
import { Toaster } from '@/shared/components/Toaster/Toaster';
import { lazy, Suspense, useEffect, useState } from 'react';

const PayStubsPage = lazy(() => import('@/pages/pay-stubs/PayStubsPage'));

// Define our Router, as well as most of our top-level logic
export const router = createMemoryRouter(
  createRoutesFromElements(
    // Top-level Parent Wrapper
    <Route
      path="/"
      element={
        <ThemePreferenceProvider>
          <LoadingGuard loadingScope={LoadingScope.global}>
            <AuthProvider>
              <WithdrawalAmountProvider>
                <ErrorBoundary FallbackComponent={BaseErrorFallback}>
                  <AppContainer>
                    <Outlet />
                  </AppContainer>
                  <Toaster />
                  <Analytics />
                </ErrorBoundary>
              </WithdrawalAmountProvider>
            </AuthProvider>
          </LoadingGuard>
        </ThemePreferenceProvider>
      }
      // Routing Error Boundary
      errorElement={<RoutingError />}
    >
      <Route path="*" element={<ErrorPage />} />
      {/* Top Level Pages! */}
      {/* Landing Page - Used to be Splash screen */}
      <Route
        index
        element={
          <TempAuthTokenGuard>
            <HomePageRedirector />
          </TempAuthTokenGuard>
        }
      />

      {/* Sunset Notice Page */}
      <Route
        path="sunset"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <WorkerIsActiveGuard>
                <SunsetPage />
              </WorkerIsActiveGuard>
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      />

      {/* Onboarding Routes */}
      <Route
        path="onboarding"
        element={
          <TempAuthTokenGuard>
            {/*
             * NOTE(Carter): The TempAuthToken Guard and the WorkerVerification
             * Guard together ensure that on any /dashboard render, we have a
             * stored authToken and we've verified it (2FA)
             */}
            <OnboardingLayout />
          </TempAuthTokenGuard>
        }
      >
        {/* A index-level redirector for the onboarding flow */}
        <Route
          index
          element={
            <WorkerVerificationGuard>
              <OnboardingRedirector />
            </WorkerVerificationGuard>
          }
        />

        {/* No Worker Verification for the Splash Screen */}
        <Route path="splash" element={<SplashScreen />} />
        <Route
          path="tutorial"
          element={
            <WorkerVerificationGuard>
              <TutorialPage />
            </WorkerVerificationGuard>
          }
        />
        <Route
          path="confirm-business"
          element={
            <WorkerVerificationGuard>
              <BusinessConfirm />
            </WorkerVerificationGuard>
          }
        />
        <Route
          path="confirm"
          element={
            <WorkerVerificationGuard>
              <ConfirmPIIPage />
            </WorkerVerificationGuard>
          }
        />

        <Route
          path="consents"
          element={
            <WorkerVerificationGuard>
              <ConsentsPage />
            </WorkerVerificationGuard>
          }
        />
        <Route
          path="activation-status"
          element={
            <WorkerVerificationGuard>
              <ActivationStatusPage />
            </WorkerVerificationGuard>
          }
        />
      </Route>

      <Route
        path="/verification"
        element={
          <TempAuthTokenGuard>
            <VerificationLayout />
          </TempAuthTokenGuard>
        }
      >
        <Route index element={<VerificationPage />} />

        <Route path="reset-request" element={<ResetRequestPage />} />
        <Route path="verify-second-contact" element={<VerifySecondaryContactPage />} />
        <Route path="new-contact" element={<NewContactPage />} />
        <Route path="verify-new-contact" element={<VerifyNewContactPage />} />
        <Route path="reset-success" element={<ResetRequestSuccessPage />} />
      </Route>

      {/* Main Dashboard */}
      <Route
        path="/dashboard"
        element={
          <TempAuthTokenGuard>
            {/*
             * NOTE(Carter): These guards ensure (in order) that we have a TAT,
             * it has been verified with user 2FA, and that user is Activated
             */}
            <WorkerVerificationGuard>
              <WorkerIsActiveGuard>
                {/* Note: we have a special history context to track history of dash navigation */}
                <DashboardHistoryProvider>
                  <DashboardLayout />
                </DashboardHistoryProvider>
              </WorkerIsActiveGuard>
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      >
        <Route index element={<HomePage />} />
        <Route path="card" element={<CardPage />} />
        <Route path="withdraw" element={<WithdrawPage />} />
        <Route path="settings" element={<SettingsPage />} />
      </Route>

      <Route
        path="/transactions"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <WorkerIsActiveGuard>
                {/*
                 * NOTE(Carter): These guard ensure (in order) that we have a TAT,
                 * it has been verified with user 2FA, and that user is Activated
                 */}
                <TransactionsLayout />
              </WorkerIsActiveGuard>
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      >
        <Route index element={<TransactionHistoryPage />} />
        <Route path="withdrawals" element={<WithdrawalHistoryPage />} />
        <Route path="details/:transactionId" element={<TransactionDetailPage />} />
      </Route>
      <Route
        path="/withdraw"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <WorkerIsActiveGuard>
                {/*
                 * NOTE(Carter): These guard ensure (in order) that we have a TAT,
                 * it has been verified with user 2FA, and that user is Activated
                 */}
                <WithdrawalFlowLayout />
              </WorkerIsActiveGuard>
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      >
        <Route index element={<RequestWithdrawalPage />} />
        <Route path="review" element={<ReviewWithdrawalPage />} />
        <Route path="result" element={<WithdrawalResultPage />} />
      </Route>
      {/* Temp for Astra debugging */}
      <Route path="/demo" element={<Navigate to="/withdrawal/review" replace={true} />} />

      <Route
        path="/signin"
        element={
          <TempAuthTokenGuard>
            <SigninPage />
          </TempAuthTokenGuard>
        }
      />

      <Route
        path="/link-bank"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <WorkerIsActiveGuard>
                {/*
                 * NOTE(Carter): These guard ensure (in order) that we have a TAT,
                 * it has been verified with user 2FA, and that user is Activated
                 */}
                <LinkBankLayout />
              </WorkerIsActiveGuard>
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      >
        <Route index element={<WithdrawalLinkWithPlaidPage />} />
        <Route path="manual" element={<LinkBankManuallyPage />} />
        <Route path="result" element={<LinkBankResultView />} />
      </Route>

      <Route
        path="/pay-stubs"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <WorkerIsActiveGuard>
                <PayStubsLayout />
              </WorkerIsActiveGuard>
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      >
        <Route
          index
          element={
            <Suspense fallback={<LoadingScreen />}>
              <PayStubsPage />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="/statements"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <WorkerIsActiveGuard>
                {/*
                 * NOTE(Carter): These guard ensure (in order) that we have a TAT,
                 * it has been verified with user 2FA, and that user is Activated
                 */}
                <StatementsLayout />
              </WorkerIsActiveGuard>
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      >
        <Route index element={<StatementsPage />} />
      </Route>
      {/* Support Pages */}
      <Route
        path="/support"
        element={
          <TempAuthTokenGuard>
            <SupportPage />
          </TempAuthTokenGuard>
        }
      />
      <Route
        path="/legal"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <LegalPage />
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      />
      <Route
        path="/taxes"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <TaxesPage />
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      />
      <Route
        path="/user-profile"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <PersonalInfoPage />
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      />
      <Route
        path="/manage-payout-methods"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <ManagePayoutMethodPage />
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      />
      <Route
        path="/setup-direct-deposit"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <LinkDirectDepositLayout />
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      >
        <Route index element={<SelectPayoutMethodPage />} />
        <Route path="manual" element={<LinkDirectDepositBankManuallyPage />} />
        <Route path="result" element={<LinkDirectDepositResultPage />} />
      </Route>
      <Route
        path="/bank-account-information"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <WorkerIsActiveGuard>
                <BankingInformationPage />
              </WorkerIsActiveGuard>
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      />
      <Route
        path="/manage-card"
        element={
          <TempAuthTokenGuard>
            <WorkerVerificationGuard>
              <WorkerIsActiveGuard>
                <ManagePinPage />
              </WorkerIsActiveGuard>
            </WorkerVerificationGuard>
          </TempAuthTokenGuard>
        }
      />
    </Route>
  )
);

// Default is a spinner centered in the whole page
const Fallback = () => <Spinner size="100vw" />;

// Allow us to pass in a TAT via URL Params
export default function App({ tempAuthToken }: { tempAuthToken: string | null }) {
  // Add the tempAuthToken here
  const [tokenPassed, setTokenPassed] = useState<string | null>(null);

  useEffect(() => {
    const localStorageAvailable = typeof window !== 'undefined' && !!window?.localStorage;
    if (tempAuthToken && tempAuthToken?.length > 0) {
      // We need the temp auth token to parseable JSON, but want our clients to not have to
      // pass in double quotes to their URL directly.
      if (tempAuthToken.startsWith('"') && tempAuthToken.endsWith('"')) {
        if (localStorageAvailable) {
          window.localStorage.setItem('temp_auth_token', tempAuthToken);
        } else {
          window.sessionStorage.setItem('temp_auth_token', tempAuthToken);
        }
        setTokenPassed(tempAuthToken);
      } else {
        if (localStorageAvailable) {
          window.localStorage.setItem('temp_auth_token', `"${tempAuthToken}"`);
        } else {
          window.sessionStorage.setItem('temp_auth_token', `"${tempAuthToken}"`);
        }
        setTokenPassed(`"${tempAuthToken}"`);
      }
    }
  }, [tempAuthToken]);

  if (!tokenPassed) {
    return process.env.NODE_ENV === 'development' ? <DevLoginPage /> : null;
  }

  return <RouterProvider router={router} fallbackElement={<Fallback />} />;
}
