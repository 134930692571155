import { DateString } from './types';

export enum ConsentDocumentIds {
  PowerOfAttorney = 'PowerOfAttorney',
  SolePropAttestation = 'SolePropAttestation',
  TermsOfService = 'TermsOfService',
  SponsorBankDisclosure = 'SponsorBankDisclosure',
  AstraDisclosure = 'AstraDisclosure',
}

export interface Consent {
  createdAt: DateString;
  documentId: ConsentDocumentIds;
  documentVersion: string;
  accepted: boolean;
  signerTimestamp: DateString;
}
