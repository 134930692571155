import ExternalLinkPoster from '@/shared/components/ExternalLinkPoster';
import { Bold, NormalText, SmallText } from '@/shared/components/Text.styled';
import { SUPPORT_LEGAL_CENTER_PAGE } from '@/shared/helpers';
import { fontSizes, fontWeights } from '@/shared/styles';
import { useState } from 'react';
import styled from 'styled-components';

const mediumSmallFontSize = `${12 / 16.0}rem`;
const ConsentText = styled(SmallText)<{ width: string }>`
  width: ${(p) => p.width};
  font-size: ${mediumSmallFontSize};
  color: ${(p) => p.theme.colors.text.secondary};
`;
const ConsentButtonText = styled(NormalText)<{ width: string }>`
  width: ${(p) => p.width};
  color: ${(p) => p.theme.colors.text.secondary};
  font-weight: ${fontWeights.bold};
`;

const List = styled.ul`
  margin-top: 2px;
  margin-left: 20px;
  list-style-type: disc;
`;
const TinyList = styled.li`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.small};
  color: ${(p) => p.theme.colors.text.secondary};
`;

const TextContainer = styled.div`
  width: fit-content;
`;

const Break = styled.div`
  height: 10px;
`;

const DocumentLink = styled.span`
  text-decoration: underline;
  color: #576c82;
`;

function PowerOfAttorney() {
  return (
    //  TODO(Carter): IS this right: width="calc(100% - 20px - 12px)"
    <TextContainer>
      <ConsentText width="calc(100% - 20px - 12px)">
        I agree that Checkr Pay may use the information provided on the previous screen to generate
        a Form W-9. I agree that the information is accurate and certify the provided information as
        follows:
      </ConsentText>
      <Break />
      <ConsentText width="calc(100% - 20px - 12px)">
        &quot;I certify, under penalty of perjury that:
      </ConsentText>
      <List>
        <TinyList>
          Taxpayer Identification Number or Social Security Number I provided is correct;
        </TinyList>
        <TinyList>
          I am not subject to backup withholding because: (a) I am exempt from backup withholding,
          or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to
          backup withholding as a result of a failure to report all interest or dividends, or (c)
          the IRS has notified me that I am no longer subject to backup withholding; and
        </TinyList>
        <TinyList>I am a U.S. citizen or other U.S. person; and</TinyList>
        <TinyList>
          The FATCA code(s) entered (if any) indicating that I am exempt from FATCA reporting is
          correct.&quot;
        </TinyList>
      </List>
      <Break />
      <ConsentText width="calc(100% - 20px - 12px)">
        You grant Checkr Pay limited power of attorney to act as your agent in processing your W-9
        Form. The Internal Revenue Service does not require your consent to any provision of this
        document other than the certifications required to avoid backup withholding.
        <Bold> If you are subject to backup withholding, please contact support.</Bold>
      </ConsentText>
      <Break />
    </TextContainer>
  );
}

function SolePropAttestation() {
  return (
    <TextContainer>
      <ConsentText width="calc(100% - 20px - 12px)">
        I acknowledge I am opening a business purpose deposit account as a 1099 sole proprietor.
        I attest I will use this account solely to manage business activities related to my
        1099 work.
      </ConsentText>
    </TextContainer>
  );
}

function TermsOfService() {
  const [shouldSendExternal, setShouldSendExternal] = useState(false);

  return (
    <TextContainer>
      <ConsentText width="calc(100% - 20px - 12px)">
        I have read and agree to the{' '}
        <DocumentLink
          onClick={() => {
            setShouldSendExternal(true);
          }}
        >
          Checkr Pay Electronic Disclosure and Consent
        </DocumentLink>
        ,{' '}
        <DocumentLink
          onClick={() => {
            setShouldSendExternal(true);
          }}
        >
          Privacy Policy
        </DocumentLink>
        ,{' '}
        <DocumentLink
          onClick={() => {
            setShouldSendExternal(true);
          }}
        >
          Terms of Service
        </DocumentLink>
        , and{' '}
        <DocumentLink
          onClick={() => {
            setShouldSendExternal(true);
          }}
        >
          Mobile Terms
        </DocumentLink>
        {shouldSendExternal && (
          <div>
            <ExternalLinkPoster
              onSent={() => setShouldSendExternal(false)}
              link={SUPPORT_LEGAL_CENTER_PAGE}
              prependText="Legal Consents"
            />
          </div>
        )}
      </ConsentText>
    </TextContainer>
  );
}

function SponsorBankDisclosure() {
  const [shouldSendExternal, setShouldSendExternal] = useState(false);

  return (
    <TextContainer>
      <ConsentText width="calc(100% - 20px - 12px)">
        I have read and agree to the{' '}
        <DocumentLink onClick={() => setShouldSendExternal(true)}>
          Thread Bank Deposit Agreement
        </DocumentLink>{' '}
        and the{' '}
        <DocumentLink onClick={() => setShouldSendExternal(true)}>
          Debit Cardholder Agreement
        </DocumentLink>
        .
        {shouldSendExternal && (
          <div>
            <ExternalLinkPoster
              onSent={() => setShouldSendExternal(false)}
              link={SUPPORT_LEGAL_CENTER_PAGE}
              prependText="Bank Consents"
            />
          </div>
        )}
      </ConsentText>
    </TextContainer>
  );
}

function AstraDisclosure() {
  const [shouldSendExternal, setShouldSendExternal] = useState(false);

  return (
    <TextContainer>
      <ConsentText width="calc(100% - 20px - 12px)">
        I have read and agree to the{' '}
        <DocumentLink onClick={() => setShouldSendExternal(true)}>
          Astra Terms of Use
        </DocumentLink>{' '}
        and the{' '}
        <DocumentLink onClick={() => setShouldSendExternal(true)}>Privacy Policy</DocumentLink>.
        {shouldSendExternal && (
          <div>
            <ExternalLinkPoster
              onSent={() => setShouldSendExternal(false)}
              link={SUPPORT_LEGAL_CENTER_PAGE}
              prependText="Astra Consents"
            />
          </div>
        )}
      </ConsentText>
    </TextContainer>
  );
}

function AgreeToAll() {
  return (
    <TextContainer>
      <ConsentButtonText width="calc(100%)">I have read and agree to all.</ConsentButtonText>
    </TextContainer>
  );
}

export {
  PowerOfAttorney,
  SolePropAttestation,
  TermsOfService,
  SponsorBankDisclosure,
  AstraDisclosure,
  AgreeToAll
};
