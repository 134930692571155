import { router } from '@/app/App';
import { ReactComponent as WarningIcon } from '@/shared/assets/circle-warning.svg';
import { ReactComponent as CardIcon } from '@/shared/assets/credit-card.svg';
import { ReactComponent as CashBackIcon } from '@/shared/assets/money-plus-line.svg';
import { ReactComponent as Office } from '@/shared/assets/office-building.svg';
import { ReactNode } from 'react';

export type BannerKey =
  | 'virtual-ready-to-spend'
  | 'physical-almost-delivered'
  | 'spend-and-earn'
  | 'business-direct-deposit'
  | 'global-warning'
  | 'increased-withdrawal-limit'
  | 'sunset-notice';

export const banners: Record<
  BannerKey,
  {
    message: string;
    icon: ReactNode;
    onAction?: () => void;
    actionText?: string;
    isClosable?: boolean;
    variant?: 'info' | 'success' | 'error';
  }
> = {
  'virtual-ready-to-spend': {
    message: `Use your virtual card to make online purchases, 
    while you wait for your card to arrive in the mail`,
    icon: <CardIcon />,
  },
  'physical-almost-delivered': {
    message: 'Your physical card is almost there and should arrive very soon. Get ready to spend!',
    icon: <CardIcon />,
  },
  'spend-and-earn': {
    message: 'Earn 1.5% cash back when you use your Checkr Pay card for purchases.',
    icon: <CashBackIcon />,
  },
  'business-direct-deposit': {
    message: `Looks like you've signed up as a business! 
      Ensure a smooth process by setting up direct deposit as soon as possible.`,
    icon: <Office />,
  },
  'global-warning': {
    message:
      `Due to the banking holiday on July 4th, you may experience some ` +
      `delay with your direct deposit payouts & standard withdrawals.`,
    icon: <Office />,
  },
  'increased-withdrawal-limit': {
    message: `Congrats! Your ATM withdrawal limit has increased to $405.`,
    icon: <CashBackIcon />,
  },
  'sunset-notice': {
    message:
      'Important: Checkr Pay service is being discontinued. ' +
      'Please withdraw your funds and download important documents.',
    icon: <WarningIcon />,
    onAction: () => router.navigate('/sunset'),
    actionText: 'Learn More',
    isClosable: false,
    variant: 'error',
  },
};
