import { ReactComponent as CloseIcon } from '@/shared/assets/close-ex.svg';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BannerKey, banners } from './banners';

const BannerContainer = styled.div<{
  variant?: 'info' | 'success' | 'error';
}>`
  display: flex;
  position: relative;
  padding: 8px;
  background: ${colors.backgroundGrey};
  border-radius: 8px;
  gap: 12px;
  align-items: center;
  border: 1px solid
    ${(p) => {
      if (p.variant === 'success') {
        return colors.accentTeal;
      }
      if (p.variant === 'error') {
        return colors.accentRed;
      }
      return p.theme.colors.buttons.primary;
    }};
  color: ${(p) => p.theme.colors.text.primary};
  width: 95%;
  margin: 20px auto;
  min-height: 32px;
  white-space: initial;
  text-align: center;
  justify-content: space-between;
  font-size: ${fontSizes.smallMedium};
  font-weight: ${fontWeights.bold};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const MessageContainer = styled.div<{ isClosable?: boolean }>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-right: ${(props) => (props.isClosable ? '32px' : '0')};
`;

const ActionText = styled.span`
  color: ${(p) => p.theme.colors.text.primary};
  text-decoration: underline;
  cursor: pointer;
  margin-left: 8px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${(p) => p.theme.colors.text.primary};
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  padding: 4px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
`;

export const Banner = ({ bannerKey }: { bannerKey: BannerKey | undefined }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isDismissed = sessionStorage.getItem(`banner_${bannerKey}_dismissed`);
    if (!isDismissed) {
      setIsVisible(true);
    }
  }, [bannerKey]);

  const handleDismiss = () => {
    setIsVisible(false);
    sessionStorage.setItem(`banner_${bannerKey}_dismissed`, 'true');
  };

  if (!isVisible || !bannerKey) return null;

  const { message, icon, onAction, actionText, isClosable = true, variant } = banners[bannerKey];
  return (
    <BannerContainer variant={variant}>
      <MessageContainer isClosable={isClosable}>
        <IconContainer>{icon}</IconContainer>
        <span>
          {message}
          {onAction && actionText && <ActionText onClick={onAction}>{actionText}</ActionText>}
        </span>
      </MessageContainer>
      {isClosable && (
        <CloseButton onClick={handleDismiss}>
          <CloseIcon />
        </CloseButton>
      )}
    </BannerContainer>
  );
};
