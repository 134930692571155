import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Check as CheckInput, Spinner } from '@checkrx/pay-component-library';

import DividedCard from '@/shared/components/DividedCard';
import { NormalText, TitleText } from '@/shared/components/Text.styled';

import { LoadingScreen } from '@/app/wrappers/Containers';
import { useConsentAndActivate } from '@/services/onboarding';
import { useFeatureFlagsFromProfile } from '@/services/useWorkerProfile';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import {
  AgreeToAll,
  AstraDisclosure,
  PowerOfAttorney,
  SolePropAttestation,
  SponsorBankDisclosure,
  TermsOfService,
} from './ConsentsCopy';

const StyledLoadingScreen = styled(LoadingScreen)`
  padding: 0 20px;
`;
const ConsentsContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center; // Center horizontally
  justify-content: start;
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ConsentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 12px;
  width: 100%;
  cursor: pointer;
`;

const ButtonRow = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

export default function ConsentsPage() {
  const {
    mutate: consentAndActivateProfile,
    isLoading,
    isSuccess,
    isError,
    isIdle,
  } = useConsentAndActivate();
  const { data: featureFlags } = useFeatureFlagsFromProfile();
  const customerFlags = featureFlags?.customerFlags;
  const skipW9 = customerFlags?.skipW9LegalConsent?.enabled;
  const defaultChecked = [skipW9 ? true : false, false, false, false, false];
  const [checkedItems, setCheckedItems] = useState(defaultChecked);

  const toggleAllChecked = () => {
    const allChecked = checkedItems.reduce((cur, prev) => cur && prev, true);
    if (allChecked) {
      setCheckedItems(defaultChecked);
    } else {
      setCheckedItems([true, true, true, true, true]);
    }
  };

  const consentChecks = (
    <DividedCard
      width="100%"
      elements={
        [
          skipW9 ? null : (
            <ConsentContainer
              className="consents-power-of-attorney-option"
              key="power-of-attorney"
              onClick={() => {
                setCheckedItems(checkedItems.map((check, idx) => (idx === 0 ? !check : check)));
              }}
            >
              <CheckInput size="30px" checked={checkedItems[0]} />
              <PowerOfAttorney />
            </ConsentContainer>
          ),
          <ConsentContainer
            className="consents-sole-prop-attestation-option"
            key="sole-prop-attestation"
            onClick={() => {
              setCheckedItems(checkedItems.map((check, idx) => (idx === 1 ? !check : check)));
            }}
          >
            <CheckInput size="30px" checked={checkedItems[1]} />
            <SolePropAttestation />
          </ConsentContainer>,
          <ConsentContainer
            className="consents-terms-of-service-option"
            key="terms-of-service"
            onClick={() => {
              setCheckedItems(checkedItems.map((check, idx) => (idx === 2 ? !check : check)));
            }}
          >
            <CheckInput size="30px" checked={checkedItems[2]} />
            <TermsOfService />
          </ConsentContainer>,
          <ConsentContainer
            className="consents-sponsor-bank-option"
            key="sponsor-bank"
            onClick={() => {
              setCheckedItems(checkedItems.map((check, idx) => (idx === 3 ? !check : check)));
            }}
          >
            <CheckInput size="30px" checked={checkedItems[3]} />
            <SponsorBankDisclosure />
          </ConsentContainer>,
          <ConsentContainer
            className="astra-disclosure-option"
            key="astra-disclosure"
            onClick={() => {
              setCheckedItems(checkedItems.map((check, idx) => (idx === 4 ? !check : check)));
            }}
          >
            <CheckInput size="30px" checked={checkedItems[4]} />
            <AstraDisclosure />
          </ConsentContainer>,
          <ConsentContainer
            className="consents-agree-all-btn"
            key="allCheck"
            onClick={() => {
              toggleAllChecked();
            }}
          >
            <CheckInput
              size="30px"
              checked={checkedItems.reduce((cur, prev) => cur && prev, true)}
            />
            <AgreeToAll />
          </ConsentContainer>,
        ].filter(Boolean) as ReactElement[]
      }
    />
  );

  if (isLoading) {
    return (
      <StyledLoadingScreen>
        <Spinner />
        <NormalText>
          We&apos;re activating your Checkr Pay account! This may take a moment...
        </NormalText>
      </StyledLoadingScreen>
    );
  }

  // On either success or 400 error (activation failed), we should redirect to
  // the activation-status page.
  if (isSuccess || isError) {
    return <Navigate to="/onboarding/activation-status" />;
  }

  const onSubmitConsent = async () => {
    consentAndActivateProfile(checkedItems);
  };

  return (
    <ConsentsContainer>
      <TitleText>Accept Consents</TitleText>
      {consentChecks}
      <ButtonRow>
        <Button
          className="consents-submit-btn"
          width="100%"
          text="Submit"
          sizeVariant="big"
          colorVariant="brand"
          disabled={!(isIdle && checkedItems.reduce((cur, prev) => cur && prev, true))}
          onClick={onSubmitConsent}
        />
      </ButtonRow>
    </ConsentsContainer>
  );
}
