import { PayoutMethodAccount, useAddPayoutMethod } from '@/services/usePayoutMethod';
import { ReactComponent as DirectDeposit } from '@/shared/assets/direct-deposit.svg';
import { colors } from '@/shared/styles';
import { PayoutMethod, WorkerProfile } from '@/shared/types';
import { Button } from '@checkrx/pay-component-library';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ExternalAccountLink } from '../../ExternalAccounts';
import { MediumWarningText } from '../../Text.styled';
import { PaymentMethodBadgeT, PaymentMethodChoice } from '../PaymentMethodChoice';
import { ConfirmationButtonsContainer } from '../../ButtonContainer.styled';

const DirectDepositChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type Props = {
  workerProfile: WorkerProfile;
  payoutMethodAccount: PayoutMethodAccount | undefined;
  hideDirectDepositActions: boolean;
};

export const DirectDepositChoice: FC<Props> = ({
  workerProfile,
  payoutMethodAccount,
  hideDirectDepositActions,
}) => {
  const { mutateAsync: changePayoutMethod } = useAddPayoutMethod();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const handleNavigateWithState = () => {
    navigate('/setup-direct-deposit', {
      state: { payoutMethod: PayoutMethod.ACHDirectDeposit },
    });
  };

  const handleUseExistingAccount = async () => {
    await changePayoutMethod({
      externalAccountId: payoutMethodAccount?.externalAccountId,
      payoutMethod: PayoutMethod.ACHDirectDeposit,
    });
  };

  const selectedPayoutMethod = workerProfile?.profile?.payoutMethod?.selected;

  const badges = [selectedPayoutMethod === PayoutMethod.ACHDirectDeposit ? 'active' : null].filter(
    Boolean
  ) as PaymentMethodBadgeT[];
  return (
    <PaymentMethodChoice
      title="Direct Deposit"
      titleIcon={<DirectDeposit stroke={colors.textGrey} />}
      badges={badges}
      bulletPoints={[
        'Payouts take 2-3 working days to complete',
        'Payouts sent direct to your bank account',
      ]}
    >
      {!hideDirectDepositActions ? (
        <>
          {payoutMethodAccount ? (
            <ExternalAccountLink
              key={payoutMethodAccount.externalAccountId}
              accountNumber={payoutMethodAccount.accountNumber}
              bankName={payoutMethodAccount.bank}
              ownerName={payoutMethodAccount.ownerName}
            />
          ) : null}
          {selectedPayoutMethod === PayoutMethod.ACHDirectDeposit && (
            <Button
              width="100%"
              text="Change bank account"
              colorVariant="accent"
              sizeVariant="small"
              onClick={handleNavigateWithState}
            />
          )}
        </>
      ) : null}
      {selectedPayoutMethod !== PayoutMethod.ACHDirectDeposit ? (
        <>
          {showConfirmation ? (
            <>
              <MediumWarningText>
                Your payouts will be sent to your selected bank account instead of the 
                Checkr Pay Wallet, typically within 1 business day.
              </MediumWarningText>
              <ConfirmationButtonsContainer>
                <Button
                  width="45%"
                  text="Confirm"
                  colorVariant="dark"
                  sizeVariant="small"
                  onClick={handleNavigateWithState}
                />
                <Button
                  width="45%"
                  text="Cancel"
                  colorVariant="light"
                  sizeVariant="small"
                  onClick={() => setShowConfirmation(false)}
                />
              </ConfirmationButtonsContainer>
            </>
          ) : payoutMethodAccount ? (
            <DirectDepositChoiceContainer>
              <Button
                width="100%"
                text="Use Current Account"
                colorVariant="dark"
                sizeVariant="small"
                onClick={handleUseExistingAccount}
              />
              <Button
                width="100%"
                text="Setup New Account"
                colorVariant="light"
                sizeVariant="small"
                onClick={handleNavigateWithState}
              />
            </DirectDepositChoiceContainer>
          ) : (
            <Button
              width="100%"
              text="Setup direct deposit"
              colorVariant="light"
              sizeVariant="small"
              onClick={() => setShowConfirmation(true)}
            />
          )}
        </>
      ) : null}
    </PaymentMethodChoice>
  );
};
